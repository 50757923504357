import { useState, useEffect } from 'react';
import './App.css';
import Navbar from './Navbar'
import {AiFillLinkedin, AiFillGithub, AiFillMail, AiFillGitlab} from 'react-icons/ai'
import {MdEmail, MdAddCall} from 'react-icons/md'
import {GrReactjs} from 'react-icons/gr'
import {SiJavascript,SiCss3,SiTailwindcss,SiRedux} from 'react-icons/si'
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  
  const skillsList = [
    {skill:'JavaScript',icon:<SiJavascript />},
    {skill:'React',icon:<GrReactjs />},
    {skill:'CSS3',icon:<SiCss3 />}, 
    {skill:'Tailwind',icon:<SiTailwindcss />}, 
    {skill:'MaterialUI',icon:<SiTailwindcss />}, 
    {skill:'Redux',icon:<SiRedux />}, 
    {skill:'GitHub',icon:<AiFillGithub />}, 
    {skill:'GitLab',icon:<AiFillGitlab />}, 
  ];

  return (
    <main className={`'px-8 md:px-20 lg:px-40 ${isDarkMode ? 'dark' : 'light'}`}>
    
    <Navbar  dark={(e) =>setIsDarkMode(e)}/>

    <section id='home' className='min-h-screen shadow-md text-center mt-4 flex flex-col justify-center'>
        <div className="mx-auto w-56 h-56 relative overflow-hidden md:h-96 md:w-96 mb-2 flex items-center justify-center">
          <img src="/ninja.png" alt="Avatar" className="w-full h-full p-6" />
        </div>
        <div className='flex justify-center'>
            <span className='text-3xl md:text-6xl font-medium' data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in">Zuber </span>
            <span className='text-3xl md:text-6xl mx-2 font-medium' data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in"> K A</span>
        </div>
        <h3 className='text-xl md:text-2xl'>React Developer</h3>
        <p className='text-sm py-1 text-black-800 md:text-lg'>Experienced React Developer specializing in building high-quality, user-centric web applications. </p>
        <div className='text-4xl py-4 flex justify-center gap-16 text-black-600'>
        <a target="_blank" href="https://www.linkedin.com/in/zuberka/"> <AiFillLinkedin /> </a> 
        <a target="_blank" href="mailto:kazuber11@gmail.com"> <MdEmail /> </a> 
        </div>
    </section>

      <section id='about'>
      <div className='flex rounded shadow-md flex-col md:flex px-2 py-4 my-2'data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in"  style={{paddingLeft:'6px'}}>
        <h1 className='text-xl px-2 mb-4 flex justify-center font-medium'style={{fontSize:'1.5rem',}}>About Me</h1>
        <p className='text-sm py-1 px-6 text-black-600 md:text-lg'>Hello! I'm Zuber, a passionate and creative front-end web developer building beautiful and functional web applications.
          With Two years of experience in web development, I specialize in crafting seamless and user-friendly experiences using cutting-edge technologies, with a primary focus on React.
        </p>
        <h1 className='px-4 mb-1 font-medium md:text-lg flex justify-center'data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in">What I Do ?</h1>
        <p className='text-sm mb-2 py-1 px-6 text-black-600 md:text-lg'data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in" >I thrive on turning ideas into engaging digital experiences. Here's what you can expect from me.</p>
        <h1 className='px-4 mb-1 font-medium md:text-lg flex justify-center'data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in">React Enthusiast</h1>
        <p className='text-sm mb-2 py-1 px-6 text-black-600 md:text-lg' data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in">React is my playground. I enjoy crafting interactive and dynamic user interfaces, working with state management libraries like Redux, and ensuring my applications are both performant and maintainable.</p>
        <h1 className='px-4 mb-1 font-medium md:text-lg flex justify-center'data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in">Responsive Design</h1>
        <p className='text-sm mb-2 py-1 px-6 text-black-600 md:text-lg' data-aos="fade-left" data-aos-offset="100" data-aos-easing="ease-in">I'm committed to creating web applications that are not just visually appealing but also work seamlessly across all devices. I'm well-versed in CSS3, SCSS, and Material-UI to achieve pixel-perfect responsive designs.</p>
        <h1 className='px-4 mb-1 font-medium md:text-lg flex justify-center'data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in">Collaboration</h1>
        <p className='text-sm mb-2 py-1 px-6 text-black-600 md:text-lg' data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in">I value teamwork and collaboration. I enjoy working closely with designers, back-end developers, and stakeholders to transform concepts into elegant, functional web solutions.</p>
      </div>
    </section>

    <section id='skills'>
      <div className='flex rounded shadow-md flex-col md:flex px-1 py-4 my-4 mb-2'  style={{paddingLeft:'6px'}}>
    <h1 className='flex justify-center font-medium text-lg' style={{fontSize:'1.5rem',color: isDarkMode ? '#a7a7a794' : 'black'}}>Skills</h1>
      <div className="mx-auto my-6 grid max-w-xl grid-cols-4 items-center gap-8" >
      {skillsList.map((val,key)=>(
        <a className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 skillstile skillBtn" data-aos="fade-up" data-aos-offset="100" data-aos-easing="ease-in">
          <div className='flex items-center'>{val.icon}<p key={key}>{val.skill}</p></div>
          </a>
          )
          )}
        </div>
      </div>
    </section>
    <section id='experience'>
        <div className='flex rounded shadow-md flex-col md:flex px-2 py-4 my-2'data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in"  style={{paddingLeft:'6px'}}>
          <h1 className='text-xl px-2 mb-4 flex justify-center font-medium'style={{fontSize:'1.5rem',}}>Experience</h1>
          <h1 className='flex justify-center mb-1 font-medium'style={{fontSize:'0.8rem',fontWeight:'300'}}>NanoSoft Engineers India (P) Ltd</h1>
          <h1 className='flex justify-center mb-1 font-medium'style={{fontSize:'0.8rem',fontWeight:'300'}}>Frontend Developer</h1>
          <h1 className='flex justify-center mb-1 font-medium'style={{fontSize:'0.8rem',fontWeight:'300'}}>September 2021 - Present (Coimbatore)</h1>
          <p className='text-sm py-1 px-6 text-black-600 md:text-lg'>I make user-friendly screens for smart facility management software at NanoSoft for the past two years. Using tools like React, I work with a team to turn designs into interactive websites. I help make sure the software works well and looks good on different devices</p>
         </div>
      </section>
    {/*--------------------------------- projects------------------------------- */}
   
    <section id="contact" className={`'mt-5 rounded ${isDarkMode ? 'bg-white' : 'bg-black'} py-8 px-8'`}>
      <div className={`${isDarkMode ? 'bg-white' :'text-black'} md:text-center`}>
        <h1 className='flex justify-center font-medium text-lg mb-4' style={{color: isDarkMode ? 'black' : 'white'}}>Contact Me</h1>
        <p className='flex justify-center text-sm' style={{color: isDarkMode ? 'black' : 'white'}}>Wanna talk anything?</p>

        <ul className='flex center justify-center gap-2 mx-1 px-0'>
          <a className='flex items-center gap-2 text-sm' target="_blank" href="tel:+919791235410"
          style={{color: isDarkMode ? 'black' : 'white'}}>
            <MdAddCall />
            <p>+91 9791235410</p>
          </a>
          <a className='flex items-center gap-2 text-sm' target="_blank" href="mailto:kazuber11@gmail.com"
          style={{color: isDarkMode ? 'black' : 'white'}}>
            <AiFillMail />
            <p>kazuber11@gmail.com</p>
          </a>
        </ul>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>

        <p className="text-center text-sm text-white sm:text-center" style={{color: isDarkMode ? 'black' : 'white'}}>
          © 2023
          <a href=""> Zuber K A</a>. All Rights Reserved.
        </p>

      </div>
      
    </section>

    </main>
  );
}

export default App;
