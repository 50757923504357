import React,{useState} from 'react';
import {IoLogoOctocat} from 'react-icons/io'
import './App.css';
export default function Navbar(props){

  const [isDarkMode, setIsDarkMode] = useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.querySelector(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
      const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
        props.dark(!isDarkMode);
      };
      const openPdfInNewTab = () => {
        const pdfUrl = 'https://zuberdev.tech/documents/Zuber_Resume.pdf';
        window.open(pdfUrl, '_blank');
      };
    return (
        <nav className="fixed top-0 left-0 w-full bg-white shadow-md md:px-6 lg:px-10 py-4 px-2 flex justify-between items-center z-50">
           <div className="text-xl text-black cursor-pointer  Navbarlogo" 
           onClick={toggleDarkMode}><IoLogoOctocat /><h1>ZUBER</h1></div>
            <ul className="flex justify-between items-start">
                {/* <a className="ml-4 text-black navitem cursor-pointer" onClick={() => scrollToSection('#home')}>Home</a>
                <a className="ml-4 text-black navitem cursor-pointer" onClick={() => scrollToSection('#about')}>About</a>
                <a className="ml-4 text-black navitem cursor-pointer" onClick={() => scrollToSection('#skills')}>Skills</a>
                <a className="ml-4 text-black navitem cursor-pointer" onClick={() => scrollToSection('#projects')}>Projects</a>
                <a className="ml-4 text-black navitem cursor-pointer" onClick={() => scrollToSection('#contact')}>Contact</a> */}
                <a className="text-black resumebtn" onClick={openPdfInNewTab} >Resume</a>

            </ul>
        </nav>
    )
}